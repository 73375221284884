:root {
    --bg-color: #f0f4f8;
    --text-color: #2d3748;
    --primary-color: #4299e1;
    --secondary-color: #a0aec0;
    --border-color: #e2e8f0;
    --chat-user-bg: #4299e1;
    --chat-bot-bg: #a0aec0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    transition: background-color 0.3s, color 0.3s;
    line-height: 1.6;
}

.container {
    max-width: fit-content;
    margin: 0 auto;
    padding: 20px;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.avatar-container {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-text {
    flex-grow: 1;
}

h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.status-message {
    margin: 5px 0 0;
    font-size: 0.9rem;
    color: var(--secondary-color);
}

#theme-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

#theme-toggle:hover {
    background-color: var(--border-color);
}

#theme-toggle svg {
    width: 24px;
    height: 24px;
    color: var(--text-color);
}

#chat-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
}

#chat-messages {
    height: 400px;
    overflow-y: auto;
    padding: 20px;
}

.message {
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 18px;
    max-width: 80%;
    animation: fadeIn 0.3s ease-out;
    position: relative;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.user-message {
    background-color: var(--chat-user-bg);
    color: white;
    align-self: flex-end;
    margin-left: auto;
}

.bot-message {
    background-color: var(--chat-bot-bg);
    color: white;
    align-self: flex-start;
}

.rating {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.rating button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px;
    margin-left: 5px;
}

#quick-responses {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 20px;
}

.quick-response-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
}

.quick-response-btn:hover {
    background-color: #3182ce;
}

#input-container {
    display: flex;
    padding: 15px;
    background-color: white;
    border-top: 1px solid var(--border-color);
    align-items: center;
}

#trigger-bot,
#attach-file {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-right: 10px;
}

#trigger-bot svg,
#attach-file svg {
    width: 24px;
    height: 24px;
    color: var(--secondary-color);
}

#user-input {
    flex-grow: 1;
    padding: 10px 15px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

#user-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

#send-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s;
}

#send-button:hover {
    background-color: #3182ce;
}

#send-button svg {
    width: 20px;
    height: 20px;
}

/* Dark mode */
body.dark-mode {
    --bg-color: #1a202c;
    --text-color: #e2e8f0;
    --primary-color: #63b3ed;
    --secondary-color: #718096;
    --border-color: #2d3748;
    --chat-user-bg: #63b3ed;
    --chat-bot-bg: #718096;
}

body.dark-mode header,
body.dark-mode #chat-container,
body.dark-mode #input-container {
    background-color: #2d3748;
}

body.dark-mode #user-input {
    background-color: #4a5568;
    color: white;
}

/* Responsive design */
@media (max-width: 600px) {
    .container {
        padding: 10px;
    }

    header {
        flex-direction: column;
        align-items: flex-start;
    }

    .avatar-container {
        margin-bottom: 10px;
    }

    #chat-messages {
        height: 350px;
    }

    .message {
        max-width: 90%;
    }

    #quick-responses {
        flex-direction: column;
    }

    .quick-response-btn {
        width: 100%;
    }
}
